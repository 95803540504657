<template>
  <router-view :user-type="userType" />
</template>
<script>
export default {
  name: 'Departments',
  computed: {
    userType() {
      // if (this.$route.name === 'department-finance' || this.$route.name === 'department-invoice') {
      if (this.$route.name === 'department-finance') {
        return this.$USER;
      }
      return this.$DEPTADMIN;
    },
  },
};
</script>
